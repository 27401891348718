/* tslint:disable */
/* eslint-disable */
/**
 * Simple Blog
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PostPreviewDto } from './PostPreviewDto';
import {
    PostPreviewDtoFromJSON,
    PostPreviewDtoFromJSONTyped,
    PostPreviewDtoToJSON,
    PostPreviewDtoToJSONTyped,
} from './PostPreviewDto';

/**
 * 
 * @export
 * @interface PagePostDto
 */
export interface PagePostDto {
    /**
     * Current page
     * @type {number}
     * @memberof PagePostDto
     */
    page: number;
    /**
     * Total pages
     * @type {number}
     * @memberof PagePostDto
     */
    totalPages: number;
    /**
     * 
     * @type {Array<PostPreviewDto>}
     * @memberof PagePostDto
     */
    items: Array<PostPreviewDto>;
}

/**
 * Check if a given object implements the PagePostDto interface.
 */
export function instanceOfPagePostDto(value: object): value is PagePostDto {
    if (!('page' in value) || value['page'] === undefined) return false;
    if (!('totalPages' in value) || value['totalPages'] === undefined) return false;
    if (!('items' in value) || value['items'] === undefined) return false;
    return true;
}

export function PagePostDtoFromJSON(json: any): PagePostDto {
    return PagePostDtoFromJSONTyped(json, false);
}

export function PagePostDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PagePostDto {
    if (json == null) {
        return json;
    }
    return {
        
        'page': json['page'],
        'totalPages': json['totalPages'],
        'items': ((json['items'] as Array<any>).map(PostPreviewDtoFromJSON)),
    };
}

  export function PagePostDtoToJSON(json: any): PagePostDto {
      return PagePostDtoToJSONTyped(json, false);
  }

  export function PagePostDtoToJSONTyped(value?: PagePostDto | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'page': value['page'],
        'totalPages': value['totalPages'],
        'items': ((value['items'] as Array<any>).map(PostPreviewDtoToJSON)),
    };
}

