/* tslint:disable */
/* eslint-disable */
/**
 * Simple Blog
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UsersPostRequest
 */
export interface UsersPostRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersPostRequest
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof UsersPostRequest
     */
    password: string;
}

/**
 * Check if a given object implements the UsersPostRequest interface.
 */
export function instanceOfUsersPostRequest(value: object): value is UsersPostRequest {
    if (!('username' in value) || value['username'] === undefined) return false;
    if (!('password' in value) || value['password'] === undefined) return false;
    return true;
}

export function UsersPostRequestFromJSON(json: any): UsersPostRequest {
    return UsersPostRequestFromJSONTyped(json, false);
}

export function UsersPostRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UsersPostRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'username': json['username'],
        'password': json['password'],
    };
}

  export function UsersPostRequestToJSON(json: any): UsersPostRequest {
      return UsersPostRequestToJSONTyped(json, false);
  }

  export function UsersPostRequestToJSONTyped(value?: UsersPostRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'username': value['username'],
        'password': value['password'],
    };
}

