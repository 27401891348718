/* tslint:disable */
/* eslint-disable */
/**
 * Simple Blog
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostEditorDto
 */
export interface PostEditorDto {
    /**
     * 
     * @type {string}
     * @memberof PostEditorDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PostEditorDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof PostEditorDto
     */
    summary: string;
    /**
     * 
     * @type {string}
     * @memberof PostEditorDto
     */
    text: string;
    /**
     * 
     * @type {Date}
     * @memberof PostEditorDto
     */
    date?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof PostEditorDto
     */
    visible: boolean;
}

/**
 * Check if a given object implements the PostEditorDto interface.
 */
export function instanceOfPostEditorDto(value: object): value is PostEditorDto {
    if (!('title' in value) || value['title'] === undefined) return false;
    if (!('summary' in value) || value['summary'] === undefined) return false;
    if (!('text' in value) || value['text'] === undefined) return false;
    if (!('visible' in value) || value['visible'] === undefined) return false;
    return true;
}

export function PostEditorDtoFromJSON(json: any): PostEditorDto {
    return PostEditorDtoFromJSONTyped(json, false);
}

export function PostEditorDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostEditorDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'title': json['title'],
        'summary': json['summary'],
        'text': json['text'],
        'date': json['date'] == null ? undefined : (new Date(json['date'])),
        'visible': json['visible'],
    };
}

  export function PostEditorDtoToJSON(json: any): PostEditorDto {
      return PostEditorDtoToJSONTyped(json, false);
  }

  export function PostEditorDtoToJSONTyped(value?: PostEditorDto | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'title': value['title'],
        'summary': value['summary'],
        'text': value['text'],
        'date': value['date'] == null ? undefined : ((value['date']).toISOString()),
        'visible': value['visible'],
    };
}

