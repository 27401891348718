/* tslint:disable */
/* eslint-disable */
/**
 * Simple Blog
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TokenDto
 */
export interface TokenDto {
    /**
     * 
     * @type {string}
     * @memberof TokenDto
     */
    token: string;
    /**
     * 
     * @type {number}
     * @memberof TokenDto
     */
    expires: number;
}

/**
 * Check if a given object implements the TokenDto interface.
 */
export function instanceOfTokenDto(value: object): value is TokenDto {
    if (!('token' in value) || value['token'] === undefined) return false;
    if (!('expires' in value) || value['expires'] === undefined) return false;
    return true;
}

export function TokenDtoFromJSON(json: any): TokenDto {
    return TokenDtoFromJSONTyped(json, false);
}

export function TokenDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TokenDto {
    if (json == null) {
        return json;
    }
    return {
        
        'token': json['token'],
        'expires': json['expires'],
    };
}

  export function TokenDtoToJSON(json: any): TokenDto {
      return TokenDtoToJSONTyped(json, false);
  }

  export function TokenDtoToJSONTyped(value?: TokenDto | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'token': value['token'],
        'expires': value['expires'],
    };
}

